import { useParams } from 'react-router-dom'
import React from 'react'

import TechnologyListing from '../Components/technologyListing/technologyListing'
import MasonryImageGrid from '../Components/masonryImageGrid/masonryImageGrid'
import { BodyText, HeadingText } from '../Components/text/text.styles'
import Navigation from '../Components/navigation/navigation'
import PageHeader from '../Components/pageHeader/pageHeader'
import { Container, Grid, Wrapper } from '../layout'
import { projectDetailsData } from '../data'

const ProjectDetailsPage = () => {
  const { id } = useParams()
  const {
    shortDescription,
    galleryHeight,
    technologies,
    description,
    images,
    title,
  } = projectDetailsData.find((project) => project.url === id)

  return (
    <Wrapper $xxsPaddingBottom="60px">
      <Navigation />
      <PageHeader title={title} shortDescription={shortDescription} />
      <Container>
        <Grid $gap="20px">
          <HeadingText>Story</HeadingText>
          <Grid $gap="40px">
            <TechnologyListing technologies={technologies} />
            <BodyText dangerouslySetInnerHTML={{ __html: description }} />
          </Grid>
          {!!images.length && (
            <Grid $gap="20px">
              <HeadingText>Gallery</HeadingText>
              <MasonryImageGrid galleryHeight={galleryHeight} images={images} />
            </Grid>
          )}
        </Grid>
      </Container>
    </Wrapper>
  )
}

export default ProjectDetailsPage
