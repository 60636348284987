import React from 'react'
import { ImageContainer, ImageOverlay, ImageStyles } from './image.styles'

const Image = ({ src, alt }) => (
  <ImageContainer>
    <ImageOverlay />
    <ImageStyles src={src} alt={alt} />
  </ImageContainer>
)

export default Image
