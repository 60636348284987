import React from 'react'

import { BodyText, HeadingText } from '../../Components/text/text.styles'
import { ContactContainer } from './contactSection.styles'
import { Mail } from '../../Components/icon/icon'
import { contactSectionData } from '../../data'
import { Container, Grid } from '../../layout'

const ContactSection = () => {
  const { title, description, email } = contactSectionData
  return (
    <Container id="contact">
      <HeadingText>{title}</HeadingText>
      <Grid $gap="40px" $xxsPaddingBottom="100px" $xlCol={2}>
        <BodyText dangerouslySetInnerHTML={{ __html: description }} />
        <ContactContainer>
          <Mail color="#1282a2" />
          <BodyText dangerouslySetInnerHTML={{ __html: email }} />
        </ContactContainer>
      </Grid>
    </Container>
  )
}

export default ContactSection
