import React, { useEffect } from 'react'
import { stagger } from 'motion'

import TechnologyItem from '../../Components/technologyItem/technologyItem'
import { HeadingText } from '../../Components/text/text.styles'
import { useAnimate, useInView } from 'motion/react'
import { Container, Grid } from '../../layout'
import { technologiesData } from '../../data'

const TechnologiesSection = () => {
  const [scope, animate] = useAnimate()
  const isInView = useInView(scope, { once: true })

  useEffect(() => {
    if (isInView) {
      animate(
        '.item',
        { opacity: 1, transform: 'translate3d(0, 0, 0) scale(1)' },
        {
          delay: stagger(0.027, {
            ease: [0.25, 1, 0.5, 1],
          }),
        },
      )
    }
  }, [isInView])

  return (
    <Container id="technologies">
      <HeadingText>Technologies</HeadingText>
      <Grid
        $gap="30px"
        $xxsCol={2}
        $xsCol={2}
        $mdCol={3}
        $xlCol={4}
        $xxlCol={5}
        ref={scope}
      >
        {technologiesData &&
          technologiesData.map(({ title, description, image, id }) => (
            <TechnologyItem
              description={description}
              image={image}
              title={title}
              key={id}
            />
          ))}
      </Grid>
    </Container>
  )
}

export default TechnologiesSection
