import styled, { css } from 'styled-components'
import { motion } from 'motion/react'
import { media } from '../../layout'

export const MasonryImagesContainer = styled.div`
  ${({ $height }) => css`
    ${media.xxs} {
      height: ${$height * 2.23}vw;
    }
    ${media.xs} {
      height: ${$height * 2.23}vw;
    }
    ${media.md} {
      height: ${$height + 30}vw;
    }
    ${media.lg} {
      height: ${$height}vw;
    }
    ${media.xl} {
      height: ${$height * 0.6}vw;
    }
    ${media.xxl} {
      height: calc(${$height / 170} * 1600px);
    }
  `}
  
  align-content: space-between;
  flex-flow: column wrap;
  display: flex;
    
    & .break {
        display: none;
    }
    
  ${media.xxs} {
    & .item {
      width: 47%;
      margin-bottom: 4%;
    }

    & .item:nth-child(2n + 1) {
      order: 1;
    }

    & .item:nth-child(2n + 2) {
      order: 2;
    }
      
    &::before,
    &::after {
      flex-basis: 100%;
      content: '';
      width: 0;
      order: 2;
    }
  }

  ${media.md} {
    & .item {
      width: 32%;
      margin-bottom: 2%;
    }

    & .item:nth-child(3n + 1) {
      order: 1;
    }

    & .item:nth-child(3n + 2) {
      order: 2;
    }

    & .item:nth-child(3n) {
      order: 3;
    }
      
    &::before,
    &::after {
        flex-basis: 100%;
        content: '';
        width: 0;
        order: 2;
    }
  }
    
  ${media.xl} {
    & .item {
      width: 24%;
      margin-bottom: 2%;
    }

    & .item:nth-of-type(4n + 1) {
      order: 1;
    }

    & .item:nth-of-type(4n + 2) {
      order: 2;
    }
    
    & .item:nth-of-type(4n + 3) {
      order: 3;
    }

    & .item:nth-of-type(4n) {
      order: 4;
    }
    & .break {
      flex-basis: 100%;
      width: 0;
      margin: 0;
      order: 2;
      content: '';
      padding: 0;
      display: block;
    }
    & .break:nth-of-type(3) {
      order: 3;
    }
    & .break:nth-of-type(2) {
      order: 2;
    }
    & .break:nth-of-type(1) {
      order: 1;
    }

    &::before,
    &::after {
      display: none;
    }
  }

  }

`

export const MasonryImage = styled(motion.img)`
  box-shadow: 0 0 40px 10px rgba(18, 130, 162, 0.3);
  transform: translate3d(0, 0, 0);
  will-change: transform, border;
  backface-visibility: hidden;
  border-radius: 10px;
  cursor: pointer;
  display: block;
  height: auto;
  width: 100%;
`

export const ModalOverlay = styled(motion.div)`
  background-image: radial-gradient(
    rgba(10, 17, 40, 0.6) 1px,
    rgba(0, 0, 16, 1) 1px
  );
  transform: translate3d(0, 0, 0);
  background-color: transparent;
  backface-visibility: hidden;
  backdrop-filter: blur(10px);
  background-size: 4px 4px;
  justify-content: center;
  width: 100vw !important;
  align-items: center;
  position: fixed;
  display: flex;
  height: 100vh;
  z-index: 10;
  left: 0;
  top: 0;
`

export const ModalImage = styled(motion.img)`
  transform: translate3d(0, 0, 0);
  will-change: transform, opacity;
  backface-visibility: hidden;
  border-radius: 10px;
  max-height: 80%;
  max-width: 80%;
`

export const NavigationButton = styled.button`
  background-image: radial-gradient(
    rgba(10, 17, 40, 0.9) 1px,
    rgba(0, 0, 16, 0.3) 1px
  );
  box-shadow: 0 0 16px 1px rgba(18, 130, 162, 0.5);
  border: 1px solid rgba(18, 130, 162, 1);
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  color: rgba(18, 130, 162, 1);
  transform: translateY(-50%);
  background-size: 4px 4px;
  place-items: center;
  border-radius: 50%;
  position: absolute;
  font-size: 24px;
  cursor: pointer;
  display: grid;
  height: 40px;
  width: 40px;
  z-index: 11;
  top: 50%;
  padding: 0;

  &:hover {
    background-image: radial-gradient(
      rgba(18, 130, 162, 0.3) 1px,
      rgba(0, 0, 16, 0.3) 1px
    );
    box-shadow: 0 0 20px 1px rgba(18, 130, 162, 0.9);
  }
`

export const CloseButton = styled(NavigationButton)`
  transform: none;
  right: 20px;
  top: 20px;
`
