import styled from 'styled-components'
import { motion } from 'motion/react'

import { media } from '../../layout'

export const BodyText = styled(motion.div)`
  font-family: 'Montserrat', sans-serif;
  will-change: transform, opacity;
  font-size: 1.25rem;
  line-height: 1.4;

  & p {
    max-width: 800px;
    color: #fefcfb;
    margin: 0;
    line-height: 1.4;
  }

  & a {
    transition: color 0.5s;
    text-decoration: none;
    font-weight: 700;
    color: #1282a2;
    line-height: 1.4;

    &:hover {
      color: #fefcfb;
    }
  }
  & span {
    color: #1282a2;
    line-height: 1.4;
    font-weight: 700;
  }
`

export const HeadingText = styled(motion.h2)`
  font-family: 'Montserrat', sans-serif;
  will-change: transform, opacity;
  box-sizing: border-box;
  font-weight: 900;
  color: #1282a2;
  line-height: 1;
  height: 130px;
  align-content: space-evenly;

  ${media.xxs} {
    margin: 15px 0 0 0;
    font-size: 2.95rem;
  }
  ${media.md} {
    margin: 25px 0 5px 0;
    font-size: 4.25rem;
  }
  ${media.xl} {
    margin: 65px 0 25px 0;
    font-size: 6.25rem;
  }
`

export const MainTitle = styled(motion.div)`
  height: 80vh;
  background-color: #0a112b;
  align-items: center;
  display: grid;
  transform-origin: 48vmin 44vh;
  ${media.md} {
    transform-origin: 48vmin 46vh;
  }
  ${media.lg} {
    height: 80vh;
    transform-origin: 48vmin 49vmin;
  }

  & h1 {
    font-family: 'Montserrat', sans-serif;
    backface-visibility: hidden;
    text-transform: uppercase;
    position: relative;
    font-weight: 900;
    line-height: 1.5;
    font-size: 13vmin;
    color: #1282a2;
    margin: 0;

    color: transparent; /* Make the text itself transparent */
    background: #1282a2; /* Dark color */
    background-clip: content-box;
    -webkit-background-clip: text; /* Clip the background to the text */
    -webkit-text-fill-color: transparent; /* Ensures the text itself is transparent */
    //mix-blend-mode: screen; /* Allows the dark background to create contrast */
  }

  & > h1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: radial-gradient(
      #0a112b77 1px,
      rgba(255, 255, 255, 0) 1px
    );
    background-size: 4px 4px;
    mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    -webkit-mask-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0)
    );
    //mix-blend-mode: multiply;
    pointer-events: none;
    z-index: 1;
  }
`

export const SubTitle = styled(motion.h4)`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.25rem;
  line-height: 1.25;
  font-weight: 500;
  text-align: left;
  color: #1282a2;
  margin: 0;
`
