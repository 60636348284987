import React, { useEffect, useState } from 'react'
import { AnimatePresence } from 'motion/react'
import { useLocation } from 'react-router-dom'

import { homeNavigationData, subNavigationData } from '../../data'
import { Container } from '../../layout'
import MobileNavigation from './mobileNavigation'
import {
  MobileMenuButtonWrapper,
  NavigationItemContainer,
  NavigationWrapper,
  MobileMenuButton,
  NavigationItem,
  NavigationLink,
} from './navigation.styles'

const Navigation = () => {
  const { pathname } = useLocation()
  const [isLoaded, setIsLoaded] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const onNavigationClick = (id) => {
    const element = document.getElementById(id)
    if (element) {
      setIsMenuOpen(false)
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  useEffect(() => {
    if (isMenuOpen) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = ''
    }

    return () => {
      document.documentElement.style.overflow = ''
    }
  }, [isMenuOpen])

  return (
    <>
      <AnimatePresence>
        {isLoaded && (
          <NavigationWrapper
            transition={{ duration: 0.6 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Container>
              <NavigationItemContainer
                $alignItems="center"
                $gap="20px"
                $xsCol="repeat(5, auto)"
                $justifyContent="start"
              >
                {pathname === '/' && (
                  <MobileMenuButtonWrapper>
                    <MobileMenuButton onClick={() => setIsMenuOpen(true)}>
                      ☰
                    </MobileMenuButton>
                  </MobileMenuButtonWrapper>
                )}
                {pathname === '/'
                  ? homeNavigationData.map(({ title, url, id }) => (
                      <NavigationItem
                        onClick={() => onNavigationClick(url)}
                        className="desktopNavigationItem"
                        key={id}
                      >
                        {title}
                      </NavigationItem>
                    ))
                  : subNavigationData.map(({ title, url, id }) => (
                      <NavigationLink key={id} to={url}>
                        {title}
                      </NavigationLink>
                    ))}
              </NavigationItemContainer>
            </Container>
          </NavigationWrapper>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!!isMenuOpen && pathname === '/' && (
          <MobileNavigation
            setOpen={setIsMenuOpen}
            onNavigationClick={onNavigationClick}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default Navigation
