import styled from 'styled-components'
import { media } from './mediaqueries'

const converter = (val) => {
  if (typeof val === 'number') return `${val}px`
  return val
}

export const Wrapper = styled('section')`
  ${media.xxs} {
    ${({ $xxsMarginBottom }) =>
      $xxsMarginBottom &&
      `
   margin-bottom: ${converter($xxsMarginBottom)};
  `}
    ${({ $xxsMarginTop }) =>
      $xxsMarginTop &&
      `
   margin-top: ${converter($xxsMarginTop)};
  `}
    ${({ $xxsPaddingBottom }) =>
      $xxsPaddingBottom &&
      `
   padding-bottom: ${converter($xxsPaddingBottom)};
  `}
    ${({ $xxsPaddingTop }) =>
      $xxsPaddingTop &&
      `
   padding-top: ${converter($xxsPaddingTop)};
  `}
  }
  ${media.md} {
    width: 100%;
    ${({ $fullHeight }) =>
      $fullHeight &&
      `
    height: 100%;
  `}
    ${({ $desktopHidden }) =>
      !!$desktopHidden &&
      `
      display: none;
  `}

    ${({ $mdMarginBottom }) =>
      $mdMarginBottom &&
      `
   margin-bottom: ${converter($mdMarginBottom)};
  `}

  ${({ $mdMarginTop }) =>
      $mdMarginTop &&
      `
   margin-top: ${converter($mdMarginTop)};
  `}

    ${({ $mdPaddingBottom }) =>
      $mdPaddingBottom &&
      `
   padding-bottom: ${converter($mdPaddingBottom)};
  `}

    ${({ $mdPaddingTop }) =>
      $mdPaddingTop &&
      `
   padding-top: ${converter($mdPaddingTop)};
  `}
  }

  ${media.lg} {
    ${({ $marginBottom }) =>
      $marginBottom &&
      `
   margin-bottom: ${converter($marginBottom)};
  `}

    ${({ $marginTop }) =>
      $marginTop &&
      `
   margin-top: ${converter($marginTop)};
  `}

    ${({ $paddingBottom }) =>
      $paddingBottom &&
      `
   padding-bottom: ${converter($paddingBottom)};
  `}

    ${({ $paddingTop }) =>
      $paddingTop &&
      `
   padding-top: ${converter($paddingTop)};
  `}
  }
  ${media.xl} {
    ${({ $xlMarginBottom }) =>
      $xlMarginBottom &&
      `
   margin-bottom: ${converter($xlMarginBottom)};
  `}

    ${({ $xlMarginTop }) =>
      $xlMarginTop &&
      `
   margin-top: ${converter($xlMarginTop)};
  `}

    ${({ $xlPaddingBottom }) =>
      $xlPaddingBottom &&
      `
   padding-bottom: ${converter($xlPaddingBottom)};
  `}

    ${({ $xlPaddingTop }) =>
      $xlPaddingTop &&
      `
   padding-top: ${converter($xlPaddingTop)};
  `}
  }
  ${media.xxl} {
    ${({ $xxlMarginBottom }) =>
      $xxlMarginBottom &&
      `
   margin-bottom: ${converter($xxlMarginBottom)};
  `}

    ${({ $xxlMarginTop }) =>
      $xxlMarginTop &&
      `
   margin-top: ${converter($xxlMarginTop)};
  `}

    ${({ $xxlPaddingBottom }) =>
      $xxlPaddingBottom &&
      `
   padding-bottom: ${converter($xxlPaddingBottom)};
  `}

    ${({ $xxlPaddingTop }) =>
      $xxlPaddingTop &&
      `
   padding-top: ${converter($xxlPaddingTop)};
  `}
  }
  ${({ $backgroundImage, $backgroundGradient }) =>
    $backgroundImage &&
    $backgroundGradient &&
    `
   background: ${$backgroundGradient}, url('${$backgroundImage}');
  `}
  ${({ $backgroundImage, $backgroundGradient }) =>
    !$backgroundImage &&
    $backgroundGradient &&
    `
   background: ${$backgroundGradient};
  `}
  ${({ $backgroundImage, $backgroundGradient }) =>
    $backgroundImage &&
    !$backgroundGradient &&
    `
   background: url('${$backgroundImage}');
  `}
  background-color: ${({ $backgroundColor = '#0a112b' }) =>
    $backgroundColor && $backgroundColor};
  color: ${({ color = '' }) => color && color};
  background-position: center center;
  box-sizing: border-box;
  background-size: cover;
  position: relative;
  ${({ $position }) =>
    $position &&
    `
   position: ${$position};
  `}
  width: 100%;
  @media (max-width: ${768 / 16}rem) {
    ${({ $mobileHidden }) =>
      !!$mobileHidden &&
      `
    display: none;
`}
  }
`
