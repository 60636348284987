import React from 'react'

import { ContentContainer } from '../styles/app.styles'
import Navigation from '../Components/navigation/navigation'
import {
  TechnologiesSection,
  ProjectsSection,
  ContactSection,
  StartSection,
  AboutSection,
} from '../sections'

const HomePage = () => (
  <div style={{ position: 'relative' }}>
    <Navigation />
    <ContentContainer>
      <StartSection />
      <AboutSection />
      <ProjectsSection />
      <TechnologiesSection />
      <ContactSection />
    </ContentContainer>
  </div>
)

export default HomePage
