import styled from 'styled-components'

export const TechnologiesContainer = styled.div`
  justify-content: flex-start;
  box-sizing: border-box;
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  max-width: 800px;
  gap: 25px;
`

export const Technology = styled.span`
  background-image: radial-gradient(
    rgba(10, 17, 40, 0.9) 1px,
    rgba(0, 0, 16, 0.3) 1px
  );
  box-shadow: 0 0 16px 1px rgba(18, 130, 162, 0.5);
  border: 1px solid rgba(18, 130, 162, 1);
  font-family: 'Montserrat', sans-serif;
  background-color: transparent;
  background-size: 4px 4px;
  text-decoration: none;
  border-radius: 10px;
  position: relative;
  font-weight: 500;
  appearance: none;
  font-size: 1rem;
  padding: 10px;
  color: #fefcfb;
`
