import { Link } from 'react-router-dom'
import React from 'react'

import {
  ProjectItemWrapper,
  ContentWrapper,
  ImageContainer,
  ClippedImage,
  Description,
  Divider,
  Title,
} from './projectItem.styles'

const ProjectItem = ({ image, url, title, description }) => {
  const bgColorMap = {
    spectral: 'rgba(8, 29, 48, 1)',
    'greenline-clean': 'rgba(255, 255, 255, 1)',
    unleash: 'rgba(0, 0, 0, 1)',
    'bbc-news-mobile': 'rgba(0, 0, 0, 1)',
    'bbc-news-website': 'rgba(0, 0, 0, 1)',
    farkastent: 'rgba(0, 0, 0, 1)',
    'nep-mediabank': 'rgba(14, 18, 46, 1)',
  }

  return (
    <ProjectItemWrapper to={`/projects/${url}`} className="item" as={Link}>
      <ImageContainer>
        <ClippedImage src={image} alt={title} $bgColor={bgColorMap[url]} />
      </ImageContainer>
      <Divider />
      <ContentWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </ContentWrapper>
    </ProjectItemWrapper>
  )
}
export default ProjectItem
