import styled from 'styled-components'
import { motion } from 'motion/react'
import { media } from '../../layout'

export const StartContainer = styled(motion.div)`
  margin: 0 auto -70vh auto;
  font-family: 'Montserrat', sans-serif;
  transform: translate3d(0px, 0px, 0px);
  box-sizing: border-box;
  align-content: start;
  overflow-x: hidden;
  overflow-y: hidden;
  color: #fefcfb;
  display: grid;
  height: 125vh;
  width: 100vw;
  z-index: 2;
  background-color: #0a112b;
  ${media.md} {
    margin: 0 auto -70vh auto;
  }
  ${media.xl} {
    margin: 0 auto -70vh auto;
  }
`

export const IconContainer = styled(motion.div)`
  transform: scale(0.8) translateY(0px);
  justify-content: center;
  align-content: center;
  position: relative;
  margin-top: 40px;
  display: grid;
`
