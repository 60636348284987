import React, { useEffect } from 'react'
import { useAnimate, usePresence } from 'motion/react'
import { stagger } from 'motion'
import { CloseButton, MobileNavigationOverlay, MobileNavItem } from './navigation.styles'
import { homeNavigationData } from '../../data'

const MobileNavigation = ({ setOpen, onNavigationClick }) => {
  const [ isPresent, safeToRemove ] = usePresence();
  const [ scope, animate ] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      const enterAnimation = async() => {
        await animate(
          scope.current,
          { borderRadius: '0', opacity: 1, transform: 'scale(1)' },
          { type: 'spring', stiffness: 300, damping: 35, visualDuration: 0.01 }
        )
        await animate(
          '.mobileMenuItem',
          { opacity: 1, transform: 'translateY(0px) scale(1)' },
          { delay: stagger(0.03, { ease: [0.25, 1, 0.5, 1] }), duration: 0.2, easing: [0.25, 1, 0.5, 1] }
        )
      }
      enterAnimation()
    } else {
      const exitAnimation = async () => {
        await animate(
          '.mobileMenuItem',
          { opacity: 0, transform: 'translateY(-20px) scale(0.8)' },
          { delay: stagger(0.04, { ease: [0.25, 1, 0.5, 1] }), duration: 0.4, easing: [0.25, 1, 0.5, 1] }
        )
        await animate(
          scope.current,
          { borderRadius: '0px 500px 5000px 500px', opacity: 0, transform: 'scale(0)' },
          { type: 'spring', stiffness: 300, damping: 25, visualDuration: 0.01 }
        )
      safeToRemove()
      }
      exitAnimation()
    }
  }, [ isPresent ]);

  return (
    <MobileNavigationOverlay ref={scope}>
      <CloseButton onClick={()=> setOpen(false)}>
        ✕
      </CloseButton>
      {homeNavigationData.map(({ title, url, id }) => (
        <MobileNavItem className="mobileMenuItem" key={id} onClick={()=> onNavigationClick(url)}>
          {title}
        </MobileNavItem>
      ))}
    </MobileNavigationOverlay>
  )
}

export default MobileNavigation