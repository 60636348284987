import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

const ScrollToTop = () => {
  const location = useLocation()

  useEffect(() => {
    // Scroll to the top of the page whenever the location changes to /projects
    if (location.pathname.startsWith('/projects')) {
      window.scrollTo(0, 0)
    }
  }, [location])

  return null
}

export default ScrollToTop
