import React from 'react'

import {
  TechnologyItemWrapper,
  ContentContainer,
  ContentWrapper,
  ImageContainer,
  ListTitle,
  Divider,
  Image,
} from './technologyItem.styles'

const TechnologyItem = ({ title, image }) => {
  return (
    <TechnologyItemWrapper className="item">
      <ImageContainer>
        <Image src={image} alt={title} />
      </ImageContainer>
      <Divider />
      <ContentWrapper>
        <ContentContainer>
          <ListTitle>{title}</ListTitle>
        </ContentContainer>
      </ContentWrapper>
    </TechnologyItemWrapper>
  )
}
export default TechnologyItem
