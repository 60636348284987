import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { motion } from 'motion/react'

import { Grid, media } from '../../layout'

export const NavigationWrapper = styled(motion.div)`
  background-image: radial-gradient(rgba(10, 17, 40, 0.4) 1px, #0a112b 1px);
  mask: linear-gradient(rgb(0, 0, 0) 60%, rgba(0, 0, 0, 0) 100%);
  background-color: transparent;
  backdrop-filter: blur(3px);
  background-size: 4px 4px;
  align-content: center;
  margin: 0 0 20px 0;
  position: fixed;
  display: grid;
  height: 70px;
  width: 100vw;
  padding: 0;
  z-index: 3;
  left: 0;
  top: 0;
  overflow: hidden;
`

export const NavigationItem = styled.button`
  font-family: 'Montserrat', sans-serif;
  transition: color 0.5s;
  text-decoration: none;
  position: relative;
  font-weight: 500;
  appearance: none;
  background: none;
  font-size: 1rem;
  color: #fefcfb;
  border: none;
  z-index: 1;

  &:hover {
    color: #1282a2;
  }

  &.active {
    visibility: visible;
  }
`

export const NavigationLink = styled(NavLink)`
  font-family: 'Montserrat', sans-serif;
  transition: color 0.5s;
  text-decoration: none;
  position: relative;
  font-weight: 500;
  appearance: none;
  background: none;
  font-size: 1rem;
  color: #fefcfb;
  border: none;
  z-index: 1;

  &:hover {
    color: #1282a2;
  }

  &.active {
    visibility: visible;
  }
`

export const MobileNavigationOverlay = styled(motion.div)`
  background-image: radial-gradient(
    rgba(10, 17, 40, 0.6) 1px,
    rgba(0, 0, 16, 1) 1px
  );
  border-radius: 0 500px 5000px 500px;
  background-color: transparent;
  backdrop-filter: blur(10px);
  transform-origin: top left;
  background-size: 4px 4px;
  place-content: center;
  place-items: center;
  transform: scale(0);
  position: fixed;
  grid-gap: 40px;
  display: grid;
  height: 100vh;
  width: 100vw;
  z-index: 5;
  opacity: 0;
  left: 0;
  top: 0;

  ${media.md} {
    display: none;
  }
`

export const MobileNavItem = styled(motion.button)`
  transform: translateY(-10px) scale(0.8);
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  position: relative;
  font-weight: 500;
  appearance: none;
  background: none;
  font-size: 2rem;
  color: #fefcfb;
  border: none;
  z-index: 1;
  opacity: 0;
`

export const NavigationButton = styled.button`
  background-image: radial-gradient(
    rgba(10, 17, 40, 0.9) 1px,
    rgba(0, 0, 16, 0.3) 1px
  );
  box-shadow: 0 0 16px 1px rgba(18, 130, 162, 0.5);
  border: 1px solid rgba(18, 130, 162, 1);
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  color: rgba(18, 130, 162, 1);
  transform: translateY(-50%);
  background-size: 4px 4px;
  place-items: center;
  border-radius: 50%;
  position: absolute;
  font-size: 24px;
  cursor: pointer;
  display: grid;
  height: 40px;
  width: 40px;
  z-index: 11;
  padding: 0;
  top: 50%;

  &:hover {
    background-image: radial-gradient(
      rgba(18, 130, 162, 0.3) 1px,
      rgba(0, 0, 16, 0.3) 1px
    );
    box-shadow: 0 0 20px 1px rgba(18, 130, 162, 0.9);
  }
`

export const CloseButton = styled(NavigationButton)`
  padding-bottom: 2px;
  place-items: center;
  font-size: 2.2rem;
  transform: none;
  line-height: 0;
  display: grid;
  left: 15px;
  top: 10px;
`

export const MobileMenuButtonWrapper = styled.div`
  display: block;

  ${media.md} {
    display: none;
  }
`

export const NavigationItemContainer = styled(Grid)`
  & .desktopNavigationItem {
    display: none;

    ${media.md} {
      display: block;
    }
  }
`

export const MobileMenuButton = styled(MobileNavItem)`
  color: rgba(18, 130, 162, 1);
  font-size: 2.6rem;
  opacity: 1;
  padding: 0;
`
