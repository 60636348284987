import { useAnimate, useInView, useScroll, useTransform } from 'motion/react'
import { MainTitle, SubTitle } from '../../Components/text/text.styles'
import { IconContainer, StartContainer } from './startSection.styles'
import React, { useEffect, useRef, useState } from 'react'
import { AngleDown } from '../../Components/icon/icon'
import { startScreenData } from '../../data'
import { Container } from '../../layout'

const StartSection = () => {
  const ref = useRef(null)
  const { title, subtitle } = startScreenData
  const { scrollYProgress } = useScroll({
    offset: ['0px 0px', '0.3vmin 0px'],
    target: ref,
  })

  const visibility = useTransform(
    scrollYProgress,
    [0, 1],
    ['visible', 'hidden'],
  )
  const iconVisibility = useTransform(
    scrollYProgress,
    [0, 0.2],
    ['visible', 'hidden'],
  )
  const opacity = useTransform(scrollYProgress, [0.7, 1], [1, 0])
  const subtitleOpacity = useTransform(scrollYProgress, [0, 0.1], [1, 0])
  const scale = useTransform(scrollYProgress, [0, 1], [1, 45])

  const [scope, animate] = useAnimate()
  const [isVisible, setVisible] = useState(false)
  const isInView = useInView(scope)

  useEffect(() => {
    if (isInView) {
      animate(
        scope.current,
        { transform: 'scale(1) translateY(-20px' },
        {
          duration: 0.9,
          easing: [0.35, 0.17, 0.3, 0.86],
          repeat: 9999999,
          repeatType: 'reverse',
        },
      ).then(() => setVisible(true))
    }
  }, [isInView, isVisible, animate])

  return (
    <StartContainer
      style={{ opacity }}
      initial={{ transform: 'translate3d(-100px, 0, 0)' }}
      animate={{ transform: 'translate3d(0px, 0, 0)' }}
      transition={{ type: 'spring', stiffness: 100, damping: 20 }}
    >
      <Container
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: 'spring', stiffness: 100, damping: 20 }}
      >
        <MainTitle
          style={{
            scale,
            opacity,
            visibility,
          }}
          dangerouslySetInnerHTML={{ __html: title }}
          ref={ref}
        />
        <SubTitle style={{ opacity: subtitleOpacity }}>{subtitle}</SubTitle>
        <IconContainer
          style={{ opacity, visibility: iconVisibility }}
          ref={scope}
        >
          <AngleDown color="#1282a2" />
        </IconContainer>
      </Container>
    </StartContainer>
  )
}

export default StartSection
