import styled from 'styled-components'
import rem from '../helpers/rem.helper'
import { media } from './mediaqueries'
import { motion } from 'motion/react'

// $backgroundImage?: string
// $backgroundColor?: string
// $marginBottom?: string
// $marginTop?: string
// $maxWidth?: number
// $alignContent?: string
// $xxsLeftEdge?: boolean
// $xxsRightEdge?: boolean
// $xsLeftEdge?: boolean
// $xsRightEdge?: boolean
// $mdLeftEdge?: boolean
// $mdRightEdge?: boolean
// $lgLeftEdge?: boolean
// $lgRightEdge?: boolean
// $xlLeftEdge?: boolean
// $xlRightEdge?: boolean
// $xxlLeftEdge?: boolean
// $xxlRightEdge?: boolean
// $tight?: boolean
// $mobileBorderless?: boolean

// the $leftEdge and $rightEdge is now $lgLeftEdge and $lgRightEdge

export const Container = styled(motion.div)`
  box-sizing: border-box;
  position: relative;
  display: grid;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: ${rem(16)};
  padding-left: ${rem(16)};
  ${({ $mobileBorderless }) =>
    $mobileBorderless &&
    `
    @media (max-width: 768px) {
      padding-left: 0px;
      padding-right: 0px;
    }
`}

  ${media.xxs} {
    ${({ $xxsLeftEdge }) =>
      $xxsLeftEdge &&
      `
      padding-left: 0;
    `}
    ${({ $xxsRightEdge }) =>
      $xxsRightEdge &&
      `
      padding-right: 0;
    `}
  }

  ${media.xs} {
    ${({ $xxsLeftEdge, $xsLeftEdge }) =>
      ($xxsLeftEdge || $xsLeftEdge) &&
      `
      padding-left: 0;
    `}
    ${({ $xxsRightEdge, $xsRightEdge }) =>
      ($xxsRightEdge || $xsRightEdge) &&
      `
      padding-right: 0;
    `}
  }

  ${media.md} {
    ${({ $xxsLeftEdge, $xsLeftEdge, $mdLeftEdge }) =>
      ($xxsLeftEdge || $xsLeftEdge || $mdLeftEdge) &&
      `
      padding-left: 0;
    `}
    ${({ $xxsRightEdge, $xsRightEdge, $mdRightEdge }) =>
      ($xxsRightEdge || $xsRightEdge || $mdRightEdge) &&
      `
      padding-right: 0;
    `}
  }

  ${media.lg} {
    padding-right: ${rem(100)};
    padding-left: ${rem(100)};
    ${({ $xxsLeftEdge, $xsLeftEdge, $mdLeftEdge, $lgLeftEdge }) =>
      ($xxsLeftEdge || $xsLeftEdge || $mdLeftEdge || $lgLeftEdge) &&
      `
      padding-left: 0;
    `}
    ${({ $xxsRightEdge, $xsRightEdge, $mdRightEdge, $lgRightEdge }) =>
      ($xxsRightEdge || $xsRightEdge || $mdRightEdge || $lgRightEdge) &&
      `
      padding-right: 0;
    `}
  }

  ${media.xl} {
    ${({ $tight }) =>
      $tight &&
      `
      padding-right: ${rem(220)};
      padding-left: ${rem(220)};
    `}

    ${({ $xxsLeftEdge, $xsLeftEdge, $mdLeftEdge, $lgLeftEdge, $xlLeftEdge }) =>
      ($xxsLeftEdge ||
        $xsLeftEdge ||
        $mdLeftEdge ||
        $lgLeftEdge ||
        $xlLeftEdge) &&
      `
      padding-left: 0;
    `}

    ${({
      $xxsRightEdge,
      $xsRightEdge,
      $mdRightEdge,
      $lgRightEdge,
      $xlRightEdge,
    }) =>
      ($xxsRightEdge ||
        $xsRightEdge ||
        $mdRightEdge ||
        $lgRightEdge ||
        $xlRightEdge) &&
      `
      padding-right: 0;
    `}
  }

  ${media.xxl} {
    ${({
      $xxsLeftEdge,
      $xsLeftEdge,
      $mdLeftEdge,
      $lgLeftEdge,
      $xlLeftEdge,
      $xxlLeftEdge,
    }) =>
      ($xxsLeftEdge ||
        $xsLeftEdge ||
        $mdLeftEdge ||
        $lgLeftEdge ||
        $xlLeftEdge ||
        $xxlLeftEdge) &&
      `
      padding-left: 0;
    `}

    ${({
      $xxsRightEdge,
      $xsRightEdge,
      $mdRightEdge,
      $lgRightEdge,
      $xlRightEdge,
      $xxlRightEdge,
    }) =>
      ($xxsRightEdge ||
        $xsRightEdge ||
        $mdRightEdge ||
        $lgRightEdge ||
        $xlRightEdge ||
        $xxlRightEdge) &&
      `
      padding-right: 0;
    `}
  }

  max-width: ${({ $maxWidth }) => ($maxWidth ? rem($maxWidth) : '1550px')};
  background-image: ${({ $backgroundImage = '' }) =>
    $backgroundImage && `url('${$backgroundImage}')`};
  background-color: ${({ $backgroundColor = '' }) =>
    $backgroundColor && `${$backgroundColor}`};
  margin-bottom: ${({ $marginBottom = '' }) =>
    $marginBottom && `${$marginBottom}`};
  margin-top: ${({ $marginTop = '' }) => $marginTop && `${$marginTop}`};
  ${({ $alignContent }) =>
    $alignContent &&
    `
    align-content: ${$alignContent};
  `}
`
