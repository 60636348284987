import styled from 'styled-components'

export const AppContainer = styled.div`
  background-color: #0a112b;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
  color: #fefcfb;
  display: grid;
  width: 100vw;

  & div,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  span {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    box-sizing: border-box;

    &::selection {
      background: #034078;
    }
  }
`

export const ContentContainer = styled.div`
  justify-content: center;
  box-sizing: border-box;
  align-content: start;
  padding: 0 75px;
  display: grid;
  width: 100vw;
  gap: 20px;
`
