import React from 'react'
import { TechnologiesContainer, Technology } from './technologyListing.styles'

const TechnologyListing = ({ technologies }) => (
  <TechnologiesContainer>
    {technologies &&
      technologies.map((technology, index) => (
        <Technology key={index}>{`${technology} `}</Technology>
      ))}
  </TechnologiesContainer>
)

export default TechnologyListing
