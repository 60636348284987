import styled from 'styled-components'

export const ContactContainer = styled.div`
  grid-template-columns: auto 1fr;
  align-items: center;
  display: grid;
  padding: 0;
  margin: 0;
  gap: 20px;
`
