import styled from 'styled-components'
import rem from '../helpers/rem.helper'
import { media } from './mediaqueries'
import { motion } from 'motion/react'

// $alignContent?: string
// $alignItems?: string
// $areas?: string[]
// $col?: string | number
// $mdCol?: string | number
// $colGap?: string
// $flow?: string
// $gap?: string
// $xxsGap?: string
// $xsGap?: string
// $mdGap?: string
// $lgGap?: string
// $xlGap?: string
// $xxlGap?: string
// $xxsRowGap?: string
// $xsRowGap?: string
// $mdRowGap?: string
// $lgRowGap?: string
// $xlRowGap?: string
// $xxlRowGap?: string
// $xxsColGap?: string
// $xsColGap?: string
// $mdColGap?: string
// $lgColGap?: string
// $xlColGap?: string
// $xxlColGap?: string
// $height?: string
// $justifyContent?: string
// $justifyItems?: string
// $justifySelf?: string
// $margin?: string
// $marginBottom?: string
// $marginLeft?: string
// $marginRight?: string
// $marginTop?: string
// $minRowHeight?: string
// $overflow?: string
// $padding?: string
// $paddingBottom?: string
// $paddingLeft?: string
// $paddingRight?: string
// $paddingTop?: string
// $mdPaddingBottom?: string
// $mdPaddingLeft?: string
// $mdPaddingRight?: string
// $mdPaddingTop?: string
// $lgPaddingBottom?: string
// $lgPaddingLeft?: string
// $lgPaddingRight?: string
// $lgPaddingTop?: string
// $xlPaddingBottom?: string
// $xlPaddingLeft?: string
// $xlPaddingRight?: string
// $xlPaddingTop?: string
// $xxlPaddingBottom?: string
// $xxlPaddingLeft?: string
// $xxlPaddingRight?: string
// $xxlPaddingTop?: string
// $xsPaddingBottom?: string
// $xsPaddingLeft?: string
// $xsPaddingRight?: string
// $xsPaddingTop?: string
// $xxsPaddingBottom?: string
// $xxsPaddingLeft?: string
// $xxsPaddingRight?: string
// $xxsPaddingTop?: string
// $mdMarginBottom?: string
// $mdMarginLeft?: string
// $mdMarginRight?: string
// $mdMarginTop?: string
// $lgMarginBottom?: string
// $lgMarginLeft?: string
// $lgMarginRight?: string
// $lgMarginTop?: string
// $xlMarginBottom?: string
// $xlMarginLeft?: string
// $xlMarginRight?: string
// $xlMarginTop?: string
// $xxlMarginBottom?: string
// $xxlMarginLeft?: string
// $xxlMarginRight?: string
// $xxlMarginTop?: string
// $xsMarginBottom?: string
// $xsMarginLeft?: string
// $xsMarginRight?: string
// $xsMarginTop?: string
// $xxsMarginBottom?: string
// $xxsMarginLeft?: string
// $xxsMarginRight?: string
// $xxsMarginTop?: string
// $row?: number | string
// $rowGap?: string
// $textAlign?: string
// $textColor?: string
// $backgroundColor?: string
// $xsCol?: string | number
// $xxsCol?: string | number
// $xxlCol?: string | number
// $xlCol?: string | number
// $horizontalHeight?: string
// $desktopHidden?: boolean
// $mobileHidden?: boolean

const autoRows = ({ $minRowHeight = rem(20) }) =>
  `minmax(${$minRowHeight}, auto)`

const frGetter = ($value) =>
  typeof $value === 'number' ? `repeat(${$value}, 1fr)` : $value

const iefrGetter = ($value, $gap) =>
  typeof $value === 'number' ? `1fr (${$gap} 1fr)[${$value - 1}]` : $value

const formatAreas = ($areas) => $areas.map(($area) => `"${$area}"`).join(' ')

const flexChildWidthGetter = ($col, $gap) =>
  typeof $col === 'number'
    ? `calc(100% /${$col} - ${$gap === '0px' ? '0.1px' : $gap})`
    : ''

export const Grid = styled(motion.div)`
  display: -ms-grid;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  display: grid;
  grid-auto-rows: ${autoRows};

  gap: ${({ $gap = '.5rem' }) => $gap && `${$gap}`};
  ${({ $colGap }) => $colGap && `column-gap: ${$colGap}`};
  ${({ $rowGap }) => $rowGap && `row-gap: ${$rowGap}`};

  ${({ $height }) => $height && `height: ${$height}`};
  ${({ $flow }) => $flow && `grid-auto-flow: ${$flow}`};

  margin: ${({ $margin }) => $margin && `${$margin}`};
  margin-bottom: ${({ $marginBottom }) => $marginBottom && `${$marginBottom}`};
  margin-right: ${({ $marginRight }) => $marginRight && `${$marginRight}`};
  margin-left: ${({ $marginLeft }) => $marginLeft && `${$marginLeft}`};
  margin-top: ${({ $marginTop }) => $marginTop && `${$marginTop}`};

  padding: ${({ $padding }) => $padding && `${$padding}`};
  padding-bottom: ${({ $paddingBottom }) =>
    $paddingBottom && `${$paddingBottom}`};
  padding-right: ${({ $paddingRight }) => $paddingRight && `${$paddingRight}`};
  padding-left: ${({ $paddingLeft }) => $paddingLeft && `${$paddingLeft}`};
  padding-top: ${({ $paddingTop }) => $paddingTop && `${$paddingTop}`};

  ${({ $backgroundColor }) =>
    $backgroundColor && `background-color: ${$backgroundColor}`};
  ${({ $justifyContent }) =>
    $justifyContent && `justify-content: ${$justifyContent}`};
  ${({ $justifyItems }) => $justifyItems && `justify-items: ${$justifyItems}`};
  ${({ $alignContent }) => $alignContent && `align-content: ${$alignContent}`};
  ${({ $justifySelf }) => $justifySelf && `justify-self: ${$justifySelf}`};
  ${({ $alignItems }) => $alignItems && `align-items: ${$alignItems}`};

  ${({ $areas }) => $areas && `grid-template-$areas: ${formatAreas($areas)}`};
  ${({ $textColor }) => $textColor && `color: ${$textColor};`}
  ${({ $textAlign }) => $textAlign && `text-align: ${$textAlign};`}
  ${({ $row }) => $row && `grid-template-rows: ${frGetter($row)}`};

  /* ------------- Defining mediaquery based behaviour of items ------------ */

  ${media.xxs} {
    grid-template-columns: ${({ $xxsCol = 1 }) => frGetter($xxsCol)};

    ${({ $xxsGap }) => $xxsGap && `grid-gap: ${$xxsGap}`};
    ${({ $xxsColGap }) => $xxsColGap && `column-gap: ${$xxsColGap}`};
    ${({ $xxsRowGap }) => $xxsRowGap && `row-gap: ${$xxsRowGap}`};

    ${({ $xxsPaddingBottom }) =>
      $xxsPaddingBottom && `padding-bottom: ${$xxsPaddingBottom}`};
    ${({ $xxsPaddingRight }) =>
      $xxsPaddingRight && `padding-right: ${$xxsPaddingRight}`};
    ${({ $xxsPaddingLeft }) =>
      $xxsPaddingLeft && `padding-left: ${$xxsPaddingLeft}`};
    ${({ $xxsPaddingTop }) =>
      $xxsPaddingTop && `padding-top: ${$xxsPaddingTop}`};

    ${({ $xxsMarginBottom }) =>
      $xxsMarginBottom && `margin-bottom: ${$xxsMarginBottom}`};
    ${({ $xxsMarginRight }) =>
      $xxsMarginRight && `margin-right: ${$xxsMarginRight}`};
    ${({ $xxsMarginLeft }) =>
      $xxsMarginLeft && `margin-left: ${$xxsMarginLeft}`};
    ${({ $xxsMarginTop }) => $xxsMarginTop && `margin-top: ${$xxsMarginTop}`};
  }

  ${media.xs} {
    ${({ $xsCol }) =>
      $xsCol && `grid-template-columns: ${frGetter($xsCol || 2)}`};

    ${({ $xsGap }) => $xsGap && `grid-gap: ${$xsGap}`};
    ${({ $xsColGap }) => $xsColGap && `column-gap: ${$xsColGap}`};
    ${({ $xsRowGap }) => $xsRowGap && `row-gap: ${$xsRowGap}`};

    ${({ $xsPaddingBottom }) =>
      $xsPaddingBottom && `padding-bottom: ${$xsPaddingBottom}`};
    ${({ $xsPaddingRight }) =>
      $xsPaddingRight && `padding-right: ${$xsPaddingRight}`};
    ${({ $xsPaddingLeft }) =>
      $xsPaddingLeft && `padding-left: ${$xsPaddingLeft}`};
    ${({ $xsPaddingTop }) => $xsPaddingTop && `padding-top: ${$xsPaddingTop}`};

    ${({ $xsMarginBottom }) =>
      $xsMarginBottom && `margin-bottom: ${$xsMarginBottom}`};
    ${({ $xsMarginRight }) =>
      $xsMarginRight && `margin-right: ${$xsMarginRight}`};
    ${({ $xsMarginLeft }) => $xsMarginLeft && `margin-left: ${$xsMarginLeft}`};
    ${({ $xsMarginTop }) => $xsMarginTop && `margin-top: ${$xsMarginTop}`};
  }

  ${media.md} {
    ${({ $mdCol }) =>
      $mdCol && `grid-template-columns: ${frGetter($mdCol || 1)}`};

    ${({ $mdGap }) => $mdGap && `grid-gap: ${$mdGap}`};
    ${({ $mdColGap }) => $mdColGap && `column-gap: ${$mdColGap}`};
    ${({ $mdRowGap }) => $mdRowGap && `row-gap: ${$mdRowGap}`};

    ${({ $mdPaddingBottom }) =>
      $mdPaddingBottom && `padding-bottom: ${$mdPaddingBottom}`};
    ${({ $mdPaddingRight }) =>
      $mdPaddingRight && `padding-right: ${$mdPaddingRight}`};
    ${({ $mdPaddingLeft }) =>
      $mdPaddingLeft && `padding-left: ${$mdPaddingLeft}`};
    ${({ $mdPaddingTop }) => $mdPaddingTop && `padding-top: ${$mdPaddingTop}`};

    ${({ $mdMarginBottom }) =>
      $mdMarginBottom && `margin-bottom: ${$mdMarginBottom}`};
    ${({ $mdMarginRight }) =>
      $mdMarginRight && `margin-right: ${$mdMarginRight}`};
    ${({ $mdMarginLeft }) => $mdMarginLeft && `margin-left: ${$mdMarginLeft}`};
    ${({ $mdMarginTop }) => $mdMarginTop && `margin-top: ${$mdMarginTop}`};
  }

  ${media.lg} {
    ${({ $col }) => $col && `grid-template-columns: ${frGetter($col || 1)}`};
    -ms-grid-columns: ${({ $col, $gap = '.5rem' }) => iefrGetter($col, $gap)};

    ${({ $lgGap }) => $lgGap && `grid-gap: ${$lgGap}`};
    ${({ $lgColGap }) => $lgColGap && `column-gap: ${$lgColGap}`};
    ${({ $lgRowGap }) => $lgRowGap && `row-gap: ${$lgRowGap}`};

    ${({ $lgPaddingBottom }) =>
      $lgPaddingBottom && `padding-bottom: ${$lgPaddingBottom}`};
    ${({ $lgPaddingRight }) =>
      $lgPaddingRight && `padding-right: ${$lgPaddingRight}`};
    ${({ $lgPaddingLeft }) =>
      $lgPaddingLeft && `padding-left: ${$lgPaddingLeft}`};
    ${({ $lgPaddingTop }) => $lgPaddingTop && `padding-top: ${$lgPaddingTop}`};

    ${({ $lgMarginBottom }) =>
      $lgMarginBottom && `margin-bottom: ${$lgMarginBottom}`};
    ${({ $lgMarginRight }) =>
      $lgMarginRight && `margin-right: ${$lgMarginRight}`};
    ${({ $lgMarginLeft }) => $lgMarginLeft && `margin-left: ${$lgMarginLeft}`};
    ${({ $lgMarginTop }) => $lgMarginTop && `margin-top: ${$lgMarginTop}`};

    & > * {
      box-sizing: border-box;
      width: ${({ $col = 1, $gap = '.5rem' }) =>
        flexChildWidthGetter($col, $gap)};
      margin-bottom: ${({ $gap }) => $gap && $gap};
    }
    @supports (display: grid) {
      & > * {
        width: initial;
        margin-bottom: initial;
      }
    }
  }

  ${media.xl} {
    ${({ $xlCol }) =>
      $xlCol && `grid-template-columns: ${frGetter($xlCol || 1)}`};
    ${({ $desktopHidden }) =>
      !!$desktopHidden &&
      `
      display: none;
    `}

    ${({ $xlGap }) => $xlGap && `grid-gap: ${$xlGap}`};
    ${({ $xlColGap }) => $xlColGap && `column-gap: ${$xlColGap}`};
    ${({ $xlRowGap }) => $xlRowGap && `row-gap: ${$xlRowGap}`};

    ${({ $xlPaddingBottom }) =>
      $xlPaddingBottom && `padding-bottom: ${$xlPaddingBottom}`};
    ${({ $xlPaddingRight }) =>
      $xlPaddingRight && `padding-right: ${$xlPaddingRight}`};
    ${({ $xlPaddingLeft }) =>
      $xlPaddingLeft && `padding-left: ${$xlPaddingLeft}`};
    ${({ $xlPaddingTop }) => $xlPaddingTop && `padding-top: ${$xlPaddingTop}`};

    ${({ $xlMarginBottom }) =>
      $xlMarginBottom && `margin-bottom: ${$xlMarginBottom}`};
    ${({ $xlMarginRight }) =>
      $xlMarginRight && `margin-right: ${$xlMarginRight}`};
    ${({ $xlMarginLeft }) => $xlMarginLeft && `margin-left: ${$xlMarginLeft}`};
    ${({ $xlMarginTop }) => $xlMarginTop && `margin-top: ${$xlMarginTop}`};

    -ms-grid-columns: ${({ $xlCol, $gap = '.5rem' }) =>
      iefrGetter($xlCol, $gap)};

    & > * {
      box-sizing: border-box;
      width: ${({ $xlCol = 1, $gap = '.5rem' }) =>
        flexChildWidthGetter($xlCol, $gap)};
      margin-bottom: ${({ $gap }) => $gap && $gap};
    }
    @supports (display: grid) {
      & > * {
        width: initial;
        margin-bottom: initial;
      }
    }
  }

  ${media.xxl} {
    ${({ $xxlCol }) =>
      $xxlCol && `grid-template-columns: ${frGetter($xxlCol || 1)}`};
    -ms-grid-columns: ${({ $xxlCol, $gap = '.5rem' }) =>
      iefrGetter($xxlCol, $gap)};

    ${({ $xxlGap }) => $xxlGap && `grid-gap: ${$xxlGap}`};
    ${({ $xxlColGap }) => $xxlColGap && `column-gap: ${$xxlColGap}`};
    ${({ $xxlRowGap }) => $xxlRowGap && `row-gap: ${$xxlRowGap}`};

    ${({ $xxlPaddingBottom }) =>
      $xxlPaddingBottom && `padding-bottom: ${$xxlPaddingBottom}`};
    ${({ $xxlPaddingRight }) =>
      $xxlPaddingRight && `padding-right: ${$xxlPaddingRight}`};
    ${({ $xxlPaddingLeft }) =>
      $xxlPaddingLeft && `padding-left: ${$xxlPaddingLeft}`};
    ${({ $xxlPaddingTop }) =>
      $xxlPaddingTop && `padding-top: ${$xxlPaddingTop}`};

    ${({ $xxlMarginBottom }) =>
      $xxlMarginBottom && `margin-bottom: ${$xxlMarginBottom}`};
    ${({ $xxlMarginRight }) =>
      $xxlMarginRight && `margin-right: ${$xxlMarginRight}`};
    ${({ $xxlMarginLeft }) =>
      $xxlMarginLeft && `margin-left: ${$xxlMarginLeft}`};
    ${({ $xxlMarginTop }) => $xxlMarginTop && `margin-top: ${$xxlMarginTop}`};

    & > * {
      box-sizing: border-box;
      width: ${({ $xxlCol = 1, $gap = '.5rem' }) =>
        flexChildWidthGetter($xxlCol, $gap)};
      margin-bottom: ${({ $gap }) => $gap && $gap};
    }
    @supports (display: grid) {
      & > * {
        width: initial;
        margin-bottom: initial;
      }
    }
  }
  @media (max-width: ${1200 / 16}rem) {
    ${({ $mobileHidden }) =>
      !!$mobileHidden &&
      `
      display: none;
    `}
  }
`
