import styled from 'styled-components'
import { motion } from 'motion/react'
import { media } from '../../layout'
import { BodyText } from '../text/text.styles'

export const Wrapper = styled.div`
  background-image: radial-gradient(
    rgba(10, 17, 40, 0.9) 1px,
    rgba(0, 0, 16, 0.7) 1px
  );
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  background-color: transparent;
  background-size: 4px 4px;
  align-content: center;
  margin-bottom: 40px;
  position: relative;
  padding-top: 60px;
  display: grid;
  width: 100%;
  gap: 40px;
`

export const Divider = styled(motion.div)`
  background-color: rgba(18, 130, 162, 1);
  position: relative;
  height: 85px;
  width: 100%;
  ${media.xxs} {
    box-shadow: 0 -10px 50px 10px rgba(18, 130, 162, 0.5);
    clip-path: polygon(0 97%, 100% 25%, 100% 90%, 0 100%);
  }
  ${media.xs} {
    box-shadow: 0 -10px 50px 10px rgba(18, 130, 162, 0.5);
    clip-path: polygon(0 97%, 100% 31%, 100% 90%, 0 100%);
  }
  ${media.md} {
    box-shadow: 0 -10px 50px 10px rgba(18, 130, 162, 0.5);
    clip-path: polygon(0 97%, 100% 31%, 100% 90%, 0 100%);
  }
  ${media.lg} {
    box-shadow: 0 -10px 50px 10px rgba(18, 130, 162, 0.5);
    clip-path: polygon(0 97%, 100% 35%, 100% 90%, 0 100%);
  }
  ${media.xl} {
    box-shadow: 0 -10px 50px 10px rgba(18, 130, 162, 0.5);
    clip-path: polygon(0 97%, 100% 28%, 100% 90%, 0 100%);
  }
`

export const Shadow = styled(motion.div)`
  box-shadow: 0 -10px 60px 14px rgba(18, 130, 162, 0.6);
  transform: rotate(-2deg) translateY(-26px);
  background-color: transparent;
  position: absolute;
  height: 0;
  width: 100%;
  bottom: 0;
  left: 0;

  ${media.xxs} {
    transform: rotate(-10deg) translateY(-26px);
  }
  ${media.xs} {
    transform: rotate(-6deg) translateY(-26px);
  }
  ${media.md} {
    transform: rotate(-5deg) translateY(-26px);
  }
  ${media.lg} {
    transform: rotate(-2deg) translateY(-26px);
  }
`

export const Description = styled(BodyText)`
  height: 200px;
  align-content: space-evenly;
  ${media.xxs} {
    height: 200px;
  }
  ${media.xs} {
    height: 150px;
  }
  ${media.md} {
    height: 130px;
  }
  ${media.lg} {
    height: 100px;
  }
`
