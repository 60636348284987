const imageOptimHelper = (url, params = 'f_auto,fl_awebp,q_auto:good/') => {
  if (url.includes('cloudinary')) {
    const splitBy = 'upload/'
    const [base, end] = url.split(splitBy)
    return `${base}${splitBy}${params}${end}`
  }
  return url
}

export default imageOptimHelper
