import React from 'react'

import { Description, Divider, Shadow, Wrapper } from './pageHeader.styles'
import { HeadingText } from '../text/text.styles'
import { Container, Grid } from '../../layout'

const PageHeader = ({ title, shortDescription }) => (
  <Wrapper>
    <Container>
      <Grid $gap="20px" $padding="60px 0 0 0">
        <HeadingText
          transition={{ type: 'spring', stiffness: 100, damping: 20 }}
          initial={{ transform: 'translateX(100px)', opacity: 0 }}
          animate={{ transform: 'translateX(0px)', opacity: 1 }}
        >
          {title}
        </HeadingText>
        <Description
          transition={{ type: 'spring', stiffness: 100, damping: 30 }}
          initial={{ transform: 'translateX(100px)', opacity: 0 }}
          animate={{ transform: 'translateX(0px)', opacity: 1 }}
          dangerouslySetInnerHTML={{ __html: shortDescription }}
        />
      </Grid>
    </Container>
    <Divider
      transition={{ type: 'spring', stiffness: 100, damping: 30 }}
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
    />
    <Shadow
      transition={{ type: 'spring', stiffness: 100, damping: 30 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    />
  </Wrapper>
)

export default PageHeader
