const customMediaQuery = (minWidth) => `@media (min-width: ${minWidth / 16}rem)`

export const media = {
  xxs: customMediaQuery(20),
  xs: customMediaQuery(576),
  md: customMediaQuery(768),
  lg: customMediaQuery(992),
  xl: customMediaQuery(1200),
  xxl: customMediaQuery(1600),
}
