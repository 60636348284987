import React, { useEffect, useState } from 'react'
import { AnimatePresence, LayoutGroup } from 'motion/react'
import {
  MasonryImagesContainer,
  NavigationButton,
  ModalOverlay,
  MasonryImage,
  CloseButton,
  ModalImage,
} from './masonryImageGrid.styles'
import { iOSSpringAnimation } from '../../Constants/constants'
import { imageOptim } from '../../helpers'

const MasonryImageGrid = ({ images = [], galleryHeight = 2000 }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const openModal = (index) => {
    setSelectedIndex(index)
    setIsOpen(true)
  }

  const closeModal = () => {
    setSelectedIndex(null)
    setIsOpen(false)
  }

  const nextImage = (e) => {
    e.stopPropagation()
    if (selectedIndex !== null) {
      setSelectedIndex((selectedIndex + 1) % images.length)
    }
  }

  const prevImage = (e) => {
    e.stopPropagation()
    if (selectedIndex !== null) {
      setSelectedIndex((selectedIndex - 1 + images.length) % images.length)
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [isOpen])

  return (
    <>
      <MasonryImagesContainer $height={galleryHeight} $count={images.length}>
        <LayoutGroup>
          {images.map(({ url, id, alt }, index) => (
            <MasonryImage
              whileHover={{
                boxShadow: '0 0 40px 10px rgba(18, 130, 162, 0.7)',
                scale: 1.03,
              }}
              onClick={() => openModal(index)}
              transition={iOSSpringAnimation}
              src={imageOptim(url)}
              className="item"
              layoutId={id}
              alt={alt}
              key={id}
            />
          ))}
          <span className="break" />
          <span className="break" />
          <span className="break" />
        </LayoutGroup>
      </MasonryImagesContainer>
      <AnimatePresence>
        {!!isOpen && (
          <ModalOverlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeModal}
          >
            <ModalImage
              src={imageOptim(images[selectedIndex].url)}
              onClick={(e) => e.stopPropagation()}
              layoutId={images[selectedIndex].id}
              transition={iOSSpringAnimation}
              key={images[selectedIndex].id}
            />
            <NavigationButton
              onClick={(e) => prevImage(e)}
              style={{ left: '20px' }}
            >
              ❮
            </NavigationButton>
            <NavigationButton
              onClick={(e) => nextImage(e)}
              style={{ right: '20px' }}
            >
              ❯
            </NavigationButton>
            <CloseButton onClick={closeModal}>✕</CloseButton>
          </ModalOverlay>
        )}
      </AnimatePresence>
    </>
  )
}

export default MasonryImageGrid
