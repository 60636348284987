import { motion } from 'motion/react'
import styled from 'styled-components'

import { media } from '../../layout'

export const TechnologyItemWrapper = styled(motion.div)`
  background-image: radial-gradient(
    rgba(10, 17, 40, 1) 1px,
    rgba(0, 0, 16, 0.9) 1px
  );
  transform: translate3d(-35px, -35px, 0) scale(0.9);
  box-shadow: 0 0 40px 10px rgba(18, 130, 162, 0.3);
  border: 1px solid rgba(18, 130, 162, 0.8);
  grid-template-rows: auto auto 68px;
  will-change: transform, opacity;
  background-color: transparent;
  color: rgba(254, 252, 251, 1);
  background-size: 4px 4px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: grid;
  padding: 0;
  opacity: 0;

  ${media.xs} {
    grid-template-rows: auto auto 88px;
  }
`

export const ImageContainer = styled.div`
  border-radius: 10px 10px 0 0;
  box-sizing: border-box;
  margin-bottom: -20px;
  position: relative;
  overflow: hidden;
  height: 130px;
  width: auto;

  ${media.xs} {
    height: 200px;
  }
`

export const ListTitle = styled.h4`
  font-family: 'Montserrat', sans-serif;
  color: inherit;
  padding: 0;
  margin: 0;
  font-size: 1rem;

  ${media.xs} {
    font-size: 1.5rem;
  }
`

export const Image = styled.img`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  object-fit: contain;
  padding: 15% 20%;
`

export const ContentContainer = styled.div`
  clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
  background-color: rgba(0, 0, 16, 0.8);
  border-radius: 0 0 10px 10px;
  align-items: center;
  text-align: center;
  padding: 10px;
  display: grid;

  ${media.xs} {
    padding: 15px;
  }
`

export const ContentWrapper = styled.div`
  filter: drop-shadow(0 0 40px rgba(18, 130, 162, 0.7));
  border-radius: 0 0 10px 10px;
  display: grid;
`

export const Divider = styled.div`
  clip-path: polygon(0 80%, 100% 30%, 100% 35%, 0% 100%);
  background-color: rgba(18, 130, 162, 1);
  transform: translateY(13px);
  position: relative;
  height: 20px;
  width: 100%;

  ${media.xs} {
    clip-path: polygon(0 95%, 100% 30%, 100% 35%, 0% 100%);
  }
`
