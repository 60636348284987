import { Route, Routes } from 'react-router-dom'
import React from 'react'

import { HomePage, ProjectDetailsPage } from './Pages'
import ScrollToTop from './helpers/scrollToTop.helper'
import { AppContainer } from './styles/app.styles'
import './App.css'

function App() {
  return (
    <AppContainer>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/projects/:id" element={<ProjectDetailsPage />} />
      </Routes>
    </AppContainer>
  )
}

export default App
