import React from 'react'

import { BodyText, HeadingText } from '../../Components/text/text.styles'
import Image from '../../Components/image/image'
import { Container, Grid } from '../../layout'
import { aboutMeData } from '../../data'
import { imageOptim } from '../../helpers'

const AboutSection = () => {
  const { title, description, image } = aboutMeData
  const optimizedImage = imageOptim(image)
  return (
    <Container id="aboutme">
      <HeadingText>{title}</HeadingText>
      <Grid $alignItems="center" $gap="30px" $xlCol={2} $mdCol="1fr 300px">
        <BodyText dangerouslySetInnerHTML={{ __html: description }} />
        <Image src={optimizedImage} alt="profile" />
      </Grid>
    </Container>
  )
}

export default AboutSection
