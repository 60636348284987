import { useAnimate, useInView, stagger } from 'motion/react'
import React, { useEffect } from 'react'

import ProjectItem from '../../Components/projectItem/projectItem'
import { HeadingText } from '../../Components/text/text.styles'
import { Container, Grid } from '../../layout'
import { projectsListData } from '../../data'

const ProjectsSection = () => {
  const [scope, animate] = useAnimate()
  const isInView = useInView(scope, { once: true })

  useEffect(() => {
    if (isInView) {
      animate(
        '.item',
        { opacity: 1, transform: 'translate3d(0, 0, 0) scale(1)' },
        {
          delay: stagger(0.027, {
            ease: [0.25, 1, 0.5, 1],
          }),
        },
      )
    }
  }, [isInView])

  return (
    <Container id="projects">
      <HeadingText>Projects</HeadingText>
      <Grid $xlCol="1fr 1fr" id="projects" $gap="30px" key="grid" ref={scope}>
        {projectsListData.map(({ title, description, url, id, image }) => (
          <ProjectItem
            description={description}
            className="item"
            title={title}
            image={image}
            url={url}
            key={id}
          />
        ))}
      </Grid>
    </Container>
  )
}

export default ProjectsSection
