import styled from 'styled-components'
import { media } from '../../layout'

export const ImageContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  height: auto;
  width: auto;
  padding: 0;
  margin: 0;

  ${media.xxs} {
    border-radius: 0 0 15px 15px;
  }
  ${media.md} {
    border-radius: 0 10px 10px 0;
  }
`

export const ImageStyles = styled.img`
  box-sizing: border-box;
  position: relative;
  object-fit: cover;
  width: 100%;
  bottom: 0;
  margin: 0;
  margin-bottom: -4px;

  ${media.xxs} {
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
  }
  ${media.md} {
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
  }
`

export const ImageOverlay = styled.div`
  background-image: radial-gradient(rgba(10, 17, 40, 0.6) 1px, #0a112b 1px);
  mask: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%);
  background-color: transparent;
  background-size: 4px 4px;
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  content: '';
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;

  ${media.xxs} {
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
  }
  ${media.md} {
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
  }
`
