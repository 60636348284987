import styled from 'styled-components'
import { motion } from 'motion/react'

import { media } from '../../layout'

export const ProjectItemWrapper = styled(motion.div)`
  background-image: radial-gradient(
    rgba(10, 17, 40, 0.9) 1px,
    rgba(0, 0, 16, 0.7) 1px
  );
  transition: border 0.3s ease-in-out 0s, box-shadow 0.3s ease-in-out 0s;
  transform: translate3d(-35px, -35px, 0) scale(0.9);
  box-shadow: 0 0 40px 10px rgba(18, 130, 162, 0.3);
  border: 1px solid rgba(18, 130, 162, 0.8);
  grid-template-columns: 260px auto 1fr;
  will-change: transform, opacity, border;
  color: rgba(254, 252, 251, 1);
  background-color: transparent;
  background-size: 4px 4px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  display: grid;
  padding: 0;
  opacity: 0;
  gap: 10px;

  ${media.xxs} {
    grid-template-columns: 1fr;
  }
  ${media.xs} {
    grid-template-columns: 260px auto 1fr;
  }

  ${media.xl} {
    grid-template-columns: 180px auto 1fr;
  }
  ${media.xxl} {
    grid-template-columns: 260px auto 1fr;
  }

  &:hover {
    box-shadow: 0 0 40px 10px rgba(18, 130, 162, 0.8);
    border: 1px solid rgba(18, 130, 162, 1);
  }
`

export const ClippedImage = styled.img`
  background-color: black;
  ${({ $bgColor }) => $bgColor && `background-color: ${$bgColor};`}
  object-fit: contain;
  box-sizing: border-box;
  height: 100%;
  width: 100%;

  ${media.xxs} {
    clip-path: none;
    border-radius: 10px 10px 0 0;
    padding: 50px 45px 50px 45px;
  }
  ${media.xs} {
    border-radius: 10px 0 0 10px;
    clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
    padding: 30px 45px 30px 30px;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  height: auto;
  width: 260px;
  z-index: 1;

  ${media.xxs} {
    box-shadow: 0 0 80px 10px rgba(18, 130, 162, 0.7);
    border-radius: 10px 10px 0 0;
    width: 100%;
  }
  ${media.xs} {
    filter: drop-shadow(0 0 40px rgba(18, 130, 162, 0.6));
    border-radius: 10px 0 0 10px;
    box-shadow: none;
  }
`

export const Title = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  color: inherit;
  padding: 0;
  margin: 0;
  ${media.xxs} {
    font-size: 1.5rem;
  }
  ${media.xs} {
    font-size: 1.7rem;
  }
  ${media.md} {
    font-size: 2rem;
  }
  ${media.xl} {
    font-size: 1.7rem;
  }
  ${media.xxl} {
    font-size: 2rem;
  }
`

export const Description = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  color: inherit;
  padding: 0;
  margin: 0;
`

export const ContentWrapper = styled.div`
  display: grid;
  padding: 25px;
  gap: 15px;

  ${media.xxs} {
    margin-left: 0;
  }
  ${media.xs} {
    margin-left: -60px;
  }
`

export const Divider = styled.div`
  clip-path: polygon(78% 0, 80% 0, 3% 100%, 0% 100%);
  background-color: rgba(18, 130, 162, 1);
  transform: translateX(-50px);
  position: relative;
  height: 100%;
  width: 50px;

  ${media.xxs} {
    display: none;
  }
  ${media.xs} {
    display: block;
  }
`
